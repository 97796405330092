import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Navbar } from 'react-bootstrap'
import Seo from '../../components/Seo'
import SeoheadData from '../../siteScriptData'
import { Link, navigate } from "gatsby";
import { IoCallSharp, IoMailSharp } from "react-icons/io5";
import { getHomeApi } from "../../api";


function Thankyou() {
    const [footerAddress, setFooterAddress] = useState({});

    useEffect(() => {
        getHomeApi().then((response) => {
            setFooterAddress(response.footer_settings);
        });
    }, []);

    let handleClick = () => {
        navigate('/game-landing')
    }
    return (
        <div className='thankyou'>
            <Seo SeoData={SeoheadData.thankyouPageSeoData}></Seo>
            {/* header */}
            <div className='header d-flex game-heder'>
                <Navbar expand="lg" className='w-100'>
                    <Container className='align-items-center h-100'>
                        <Link to='/' className='logo'>
                            <img className='logo' src='../../assets/img/new-logo.svg' alt="vasu-LOGO" />
                        </Link>
                        <div className='d-flex align-items-center head pe-sm-2 pe-0'>
                            <div className="d-flex align-items-center me-lg-4 me-sm-2 me-1">
                                <Link to="tel:+917359349940" className="head-circle">
                                    <IoCallSharp />
                                </Link>
                                <Link to="tel:+917359349940">
                                    <p className="d-lg-block d-none">(+91) 7359349940</p>
                                </Link>
                            </div>
                            <div className="d-flex align-items-center">
                                <Link to="mailto:info@vasundhara.io" className="head-circle">
                                    <IoMailSharp />
                                </Link>
                                <Link to="mailto:info@vasundhara.io" className="text-lowercase">
                                    <p className="d-lg-block d-none">info@vasundhara.io</p>
                                </Link>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </div>

            <div className='container'>
                <div className='thankyou-page'>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className="thankyou-img text-center">
                                <img src="../assets/img/thankyou/thankyou.svg" alt="thankyou" className='img-fluid' />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="thankyou-contain">
                                <h2>Thank you! Your <br />
                                    requirements have been submitted
                                    successfully.</h2>
                                <h3>We will contact you soon.</h3>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <section className="position-relative footer-mt game-footer">
                <div className="footer footer-content ">
                    <div className="container lets-up-footer">
                        <div className="lets-box lets-game-box">
                            <Row className="align-items-center">
                                <Col lg={8}>
                                    <div className="lets-left-box">
                                        <h5>What can we help you develop?</h5>
                                        <h2>Let’s Build Your Dream Game, Together!</h2>
                                    </div>
                                </Col>
                                <Col lg={4} className="mt-lg-0 mt-3">
                                    <div className="lets-right-box text-lg-end text-center mt-lg-0 mt-3">
                                        <button className="lets-btn" onClick={handleClick}>Get started</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="on-social border-0 mt-20-m">
                        <div className="container">
                            <div className="row align-items-center justify-content-center ">
                                <Col lg={12} className="">
                                    <div className="social-icon d-flex justify-content-center">
                                        <p className="mb-0 md-text-left text-center">Copyright @2022 Vasundhara Infotech All Right Reserved.</p>
                                    </div>
                                </Col>

                                <Col lg={12} className="mt-4">
                                    <div className="social-icon d-flex align-items-center justify-content-center">
                                        <a href={footerAddress.linked_in} target="_blank">
                                            <img src="../../assets/img/Footer/link.png" alt="link" />
                                        </a>
                                        <a href={footerAddress.youtube} target="_blank">
                                            <img
                                                src="../../assets/img/Footer/Vector4.png"
                                                alt="Vector4"
                                            />
                                        </a>
                                        <a href={footerAddress.behance} target="_blank">
                                            <img
                                                src="../../assets/img/Footer/Vector3.png"
                                                alt="Vector3"
                                            />
                                        </a>
                                        <a href={footerAddress.facebook} target="_blank">
                                            <img
                                                src="../../assets/img/Footer/Vector2.png"
                                                alt="Vector2"
                                            />
                                        </a>
                                        <a href={footerAddress.twitter} target="_blank">
                                            <img
                                                src="../../assets/img/Footer/Vector1.png"
                                                alt="Vector1"
                                            />
                                        </a>
                                        <a href={footerAddress.skype} target="_blank">
                                            <img
                                                src="../../assets/img/Footer/Vector.png"
                                                alt="Vector"
                                            />
                                        </a>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Thankyou